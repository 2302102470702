/* sidebar.css */
/* Styling for the sidebar */

@import '../../App.css'; /* Importing global styles */

/* Sidebar container */
.sidebar {
    display: flex;
    flex-direction: column; /* Stack items vertically */
    position: fixed; /* Stay in place even when scrolling */
    top: 0;
    bottom: 0;
    width: 4rem; /* Sidebar width */
    background-color: var(--header-color-light); /* Background from CSS variable */
    font-weight: 400;
    font-size: 1.03rem;
    color: var(--text-light); /* Text color from CSS variable */
    z-index: 101; /* Ensure sidebar is above other elements */
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    margin-top: 4rem;
}

/* Sidebar heading */
.sidebar h1 {
    font-weight: normal;
    font-size: calc(1rem + 0.5vw); /* Responsive font size */
}

/* Horizontal line styling */
.sidebar hr {
    border: 0;
    clear: both;
    display: block;
    width: 96%;
    background-color: var(--light);
    height: 1px;
}

.sidebar-top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 4rem;
    width: 100%;
    padding: 0;
    /*position: relative;*/
}

/*.sidebar-top.close{*/
/*    justify-content: end;*/
/*    padding:0;*/
/*    border: none;*/
/*}*/

/*.company-name {*/
/*    flex: 1;*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: end;*/
/*    !* other styles *!*/
/*}*/

/* Sidebar navigation items */
.sidebar-item , .hme{
    display: flex;
    align-items: center;
    justify-content: center;
    height: 2.5rem;
    position: relative;
    padding: 0.4rem 1rem;
    font-size: 1rem;
    /*top: 10vh;*/
    font-weight: normal;
    color: var(--secondary); /* Secondary text color */
    text-decoration: none;
    background: transparent;
    border: 0;
    transition: color 0.15s ease-in-out, background-color 0.5s ease-in-out, border-color 0.15s ease-in-out;
}

.sidebar-item{
    margin-top: 1rem; /* Space between items */
}

/*.sidebar-item.close{*/
/*    display: flex;*/
/*    justify-content: center;*/
/*    align-items: center;*/
/*    width: auto;*/
/*    overflow: hidden;*/
/*    !*transition: all;*!*/
/*    padding:0;*/
/*}*/



.sidebar-image-container{
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.sidebar-icon {
    font-size: 5rem;
    color: #272C34;
    background-color: transparent !important;
}

.light{
    background-color: var(--header-color-light);
    color: var(--text-dark);
}

.dark{
    background-color: var(--header-color-dark);
    color: var(--text-light);
}

.sidebar-images{
    width: 3rem;
    height: 2rem;
    object-fit: cover;
}

/*.hme{*/
/*    margin: 1rem 0 1rem 0;*/
/*}*/

/* Logo container */
/*.logo-container {*/
/*    display: flex;*/
/*    justify-content: center; !* Horizontally center the logo *!*/
/*    align-items: center; !* Vertically center the logo *!*/
/*    width: 100%; !* Take full width of sidebar *!*/
/*    height: 75%; !* Set an appropriate height to fit the logo *!*/
/*    overflow: hidden; !* Prevent overflow in case the logo exceeds the container *!*/
/*}*/

/* Logo image styling */
.sidebar-logo {
    height: 100%; /* Maintain aspect ratio */
    width: auto;
    object-fit: contain;
}

/*.sidebar-logo.close{*/
/*    width: 84px;*/
/*    height: 50px;*/
/*    !*padding-left: 20px;*!*/
/*}*/
/* Sidebar when open */
/*.sidebar.open {*/
/*    transition: 0.2s; !* Transition for opening *!*/
/*}*/


/* Sidebar when closed */
/*.sidebar.close {*/
/*    transition: 0.2s; !* Transition for closing *!*/
/*    width: 3.2rem;*/
/*}*/

/*!* Media queries for larger screens (desktops, tablets) *!*/
/*!*@media (min-width: 768px), (pointer:none), (pointer:coarse) {*!*/
/*!*    .sidebar {*!*/
/*!*        top: 0;*!*/
/*!*        left: 0;*!*/
/*!*        width: 4rem; !* Fixed width for larger screens *!*!*/
/*!*        !*transform: translateX(-80%); !* Hide sidebar off-screen to the left -80%*!*!*!*/
/*!*    }*!*/

/*!*    .sidebar.open {*!*/
/*!*        transform: translateX(0); !* Slide in from the left when open *!*!*/
/*!*    }*!*/


/*}*/

/* Mobile specific styles */
@media (max-width: 767px), (pointer:none), (pointer:coarse) {
    .sidebar {
        margin-top: 10vh; /* Space from the top */
        width: 100%; /* Full viewport width */
        transform: translateY(-100%); /* Hide sidebar off-screen above */
        border: 0;
        left: 0;
        background-color: var(--header-color-light); /* Mobile background */
    }

    .sidebar.close {
        transform: translateX(0) translateY(-300%); /* Hide above the viewport */
        margin: 0;
    }

    .sidebar.open {
        transform: translateX(0) translateY(0); /* Slide in from the top when open */
    }
}

/* Burger menu (hamburger icon) styling */
.burger-menu {
    flex-shrink: 0; /* Prevent it from shrinking */
}

.menu-icon{
    color: white;
    font-size: 5rem;
    position: absolute;  /* Position icon button relative to the sidebar-top */
    right: -6px;        /* Shift it to the right, with part of it going out of the container */
    top: 25%;            /* Vertically center it */
    transform: translateY(-50%);  /* Adjust to align it properly at the center */
}


/*.burger-menu-container {*/
/*    !*position: fixed;*!*/
/*    display:flex;*/
/*    top: 10px; !* Adjust as needed *!*/
/*    left: 10px; !* Adjust as needed *!*/
/*    !*z-index: 1002; !* Higher than the sidebar *!*!*/
/*}*/

/* Transforming burger menu into an arrow when active */
/* First line */
/*.burger-menu .burger-line:nth-child(1) {*/
/*    transform: translateY(-3px) rotate(45deg); !* Rotate to form an arrow *!*/
/*}*/

/*!* Middle line *!*/
/*.burger-menu .burger-line:nth-child(2) {*/
/*    opacity: 0; !* Hide middle line *!*/
/*}*/

/*!* Third line *!*/
/*.burger-menu .burger-line:nth-child(3) {*/
/*    transform: rotate(-45deg); !* Rotate to form an arrow *!*/
/*}*/

/* Active state of burger menu */
/*.burger-menu.active {*/
/*    transform: translateX(200px) rotate(0deg); !* Move menu when active *!*/
/*}*/

/* Adjust lines when burger menu is active */
/*.burger-menu.active .burger-line:nth-child(1) {*/
/*    transform: translateY(-3px) rotate(135deg); !* Adjust rotation *!*/
/*}*/

/*.burger-menu.active .burger-line:nth-child(2) {*/
/*    opacity: 0; !* Middle line remains hidden *!*/
/*}*/

/*.burger-menu.active .burger-line:nth-child(3) {*/
/*    transform: rotate(-135deg); !* Adjust rotation *!*/
/*}*/

/* Burger menu line styling */
/*.burger-line {*/
/*    width: 30px;*/
/*    height: 3px;*/
/*    background-color: var(--light); !* Line color *!*/
/*    margin: 5px 0; !* Space between lines *!*/
/*    !*transition: transform 0.5s ease-in-out, opacity 0.5s ease-in-out;*! !* Optional transitions *!*/
/*}*/



/* Default link styling */
.link {
    color: var(--text-light); /* Link text color */
    user-select: none; /* Prevent text selection */
    transition: 0.5s; /* Transition for hover effects */
    cursor: pointer;
}

/*.sidebar-item.close{*/
/*    width: auto;*/
/*    padding: 0.4rem;*/
/*}*/

/*.sidebar-item-text.close{*/
/*   display:none;*/
/*}*/

/* Hover effect for links */
.link:hover {
    background-color: rgba(255, 255, 255, 0.2); /* Light background on hover */
    transition: color 0.15s ease-in-out, background-color 0.5s ease-in-out, border-color 0.15s ease-in-out;
}

/* Active link styling */
.active-link {
    background-color: rgba(255, 255, 255, 0.3); /* Slightly darker background */
    color: var(--text-light); /* Active link text color */
    transition: 0.5s;
    cursor: pointer;
}

/* Dropdown menu styling */
.dropdown {
    position: absolute;
    /*left: calc(14rem + 2rem); /* Position adjusted based on sidebar width */
    top: 0;
    background-color: rgba(255, 255, 255, 0.3); /* Dropdown background */
    /*box-shadow: 0 0px 3px rgba(0, 0, 0, 0.2); /* Optional drop shadow */
    width: 100%; /* Full width */
    box-sizing: border-box; /* Include padding and border in total width */
    color: var(--text-light); /* Dropdown text color */
}

/* Hover effect for dropdown */
.dropdown:hover {
    /* Optional hover effects can be added here */
    transition: color 0.15s ease-in-out, background-color 0.5s ease-in-out, border-color 0.15s ease-in-out;
}

/* Links inside dropdown */
.dropdown a {
    color: var(--text-light); /* Link text color */
    cursor: pointer;
    display: block;
    text-decoration: none;
    font-size: small;
    padding: 5px 5px 5px 4vh; /* Padding with left indentation */
}

/* Hover effect for dropdown links */
.dropdown a:hover {
    background-color: var(--menu-hover); /* Hover background color from CSS variable */
    opacity: 0.7; /* Slight transparency on hover */
    transition: color 0.15s ease-in-out, background-color 0.5s ease-in-out, border-color 0.15s ease-in-out;
}

.navigation{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.popout{
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    left: 100%; /* Adjust this to position the popout to the right of the sidebar */
    top: -10px;
    width: 8rem; /* Adjust width as needed */
    background-color: white; /* Adjust as needed */
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.35); /* Optional shadow for better visibility */
    z-index: 100; /* Make sure it appears above other elements */
    border: 2px transparent;
    border-radius: 1rem;
    font-weight: bold;
    /*margin-left: 0.5rem;*/
    overflow: hidden;
}

.popoutj {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: fixed;
    top: 4rem;  /* Start from the top of the sidebar */
    left: 4rem; /* Align to the right of the sidebar width */
    width: 7.2rem; /* Adjust width as needed */
    height: calc(100vh - 4rem); /* Adjust to fit within the sidebar height */
    background: rgba(255, 255, 255, 0.1);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    /*box-shadow: 4px 4px 8px rgba(0, 0, 0, 0.35);*/
    font-weight: bold;
    z-index: 100; /* Ensure it appears above other elements */
    overflow: hidden;
    animation: slideInLeft 400ms ease-out forwards;
    border-right: 1px solid rgba(255, 255, 255, 0.18);
}


.close{
    animation: slideInRight 400ms ease-out forwards;
}

.dark-md{
    color: var(--text-light) !important;
}

.popoutj.dark-md a{
    color: var(--text-light);
}

.popoutj a, .popout a {
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    color: var(--dark);
    cursor: pointer;
    text-decoration: none;
    font-size: small;
    padding: 5px 0 5px 0; /* Padding with left indentation */
    margin-bottom: 5px;
    gap:5px;
}

.popoutj h6, .popout h6 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: var(--font-family);
    font-weight: bold;
    width: 100%;
    height: 3rem;
    margin: 0;
    padding: 3px;
}

.popout a:hover, .popoutj a:hover {
background-color: rgba(255, 255, 255, 0.54);
}

.page-divider{
    background-color: var(--dark);
}

.icon{
    padding-right: 4px;
    stroke-width: 0.5px;
    color: var(--text-dark);
}

/* Display heading styling (if used elsewhere) */
.display-4 {
    font-size: calc(1.475rem + 2.7vw); /* Responsive font size */
    font-weight: 300;
    line-height: 1.2;
}



/* Fade effect (if used) */
.fade {
    opacity: 0; /* Start fully transparent */
    transition: opacity 1s ease-in-out; /* Fade in/out */
}

.fade.show {
    opacity: 1; /* Fully opaque when shown */
}

