@import '../../App.css';

.mui-table {
    min-width: 800px;
    table-layout: fixed; /* Change from auto to fixed for consistent column widths */
    border-collapse: collapse; /* Ensures there are no gaps between borders */
    font-family: 'Roboto', sans-serif; /* Change from monospace for better readability */
    font-size: 1rem;
    color: var(--light);
    background: var(--light);
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth */
    overflow-y: auto; /* Keeps vertical scroll */
    overflow-x: hidden; /* Removes horizontal scroll, if unnecessary */
    max-height: 20rem; /* Adjust based on your needs */
    border-radius: 0; /* Optional: Rounds the corners */
}

.mui-table th, .mui-table td {
    padding: 12px 16px; /* Adds padding for spacing */
    text-align: left; /* Aligns text to the left */
    font-weight: normal; /* Normal weight for th for modern look, can adjust */
    border-bottom: 1px solid var(--dark-gray); /* Adds separation between rows */
    overflow-wrap: break-word; /* Breaks words to prevent overflow */
}

.mui-table th {
    background-color: var(--main); /* Adds a distinct background to header */
    color: var(--light); /* Light text for contrast against header background */
    font-weight: bold; /* Bold text for headers */
}

.mui-table th:hover {
    background-color: var(--main); /* Keep background the same on hover */
    color: var(--light); /* Keep text color the same on hover */
}
.mui-table th:hover {
    color: var(--light); /* Keep text color the same on hover */
}

.mui-table tbody tr:hover {
    background-color: var(--light-blue); /* Highlight row on hover for interactivity */
}

.fontMono {
    font-family: 'Roboto Mono', monospace; /* More specific monospace font for better clarity */
    font-size: 1rem; /* Consistent font size */
}

/* Additional styling for paragraphs within table cells */
.mui-table p {
    margin: 0; /* Removes default margin */
    padding: 0; /* Ensures no extra padding */
    color: var(--text-black); /* Ensures readability */
}

.table-container {
    width: auto;
    overflow-x: auto; /* Keeps the horizontal overflow hidden; adjust as needed */
    border: 0;
    border-radius: 0; /* Optional: Matches the table's border-radius for consistency */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Optional: Adds shadow for depth, match with your table styling */
    border-top: 1px solid var(--dark-gray); /* Adds a distinct border on top */
    box-sizing: border-box;
    max-height: 50vh;
    padding: 0;
    margin: 0 !Important;
}

.MuiTableCell-root {
    padding: 0;
}

.MuiTableContainer-root {
    padding: 0;
    margin: 0;
}

