.canvas-display {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin-bottom: 60px;
    gap: 10px;
}
.editNonogramTitle{
    text-align: center;
}
@media (max-width: 768px), (pointer:none), (pointer:coarse) {
    .canvas-display {
        flex-direction: column;
    }
}