.user-manager {
    padding-top: 20px;
}

.user-manager-header {
    display: flex;
    align-items: center;
    justify-content: left;
    position: relative;
    margin-bottom: 20px;
}

.user-manager-header h2 {
    margin: 0 20px;
    font-size: 24px;
    position: relative;
    z-index: 1; /* Ensure text is above the lines */
    background: #fff; /* Match this to your background color */
    padding: 0 10px; /* Optional: adds padding around the text */
}

.stacked {
    display: flex;
    flex-direction: row;
    margin: 0;
    padding-right: 5px;
    height: auto;
}

.user-manager-header::after {
    content: '';
    position: absolute;
    top: 50%;
    width: 100%;
    height: 1px;
    background: #ccc;
    z-index: 0; /* Ensure lines are behind the text */
}

.form-section {
    margin-top: 20px;
}

