/*default_page_style.css*/
/* Styling for the default page */

@import '../../App.css';

@keyframes slideInFromLeft {
    0% {
        transform: translateX(100%);
        opacity: 0;
    }
    100% {
        transform: translateX(0);
        opacity: 1;
    }
}

.slide-in-from-left {
    animation: slideInFromLeft 0.5s ease-out forwards;
}

.data_grid {
    background-color: var(--grey);
    color: var(--light);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.data_grid header {
    background-color: var(--main);
    color: var(--light);
}

.custom-table {
    border-top: 1px solid lightgray;
    border-radius: 0;
}

.custom-checkbox .MuiSvgIcon-root {
    color: var(--main);
}


.button-stretch {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0.5vh 1vw 0.5vh 1vw;
    z-index:103;
}

.padding-on {
    height: 100vh;
    width:100vw;
    padding : 5vh 0 2vh 5vw;
    /*margin-bottom: 4vh;*/
}

.default_page_style {
    display: flex;
    height: 100%;
    width: 100%;
    color: var(--light);
    cursor: default;
    z-index:98;
    padding: 4rem 0 2rem 4rem;
    justify-content: flex-start;
    box-sizing: border-box;
    flex-direction: column;
    align-items: center;
    /*overflow-y: scroll;*/
}

.box {
    border-radius: 4px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.light{
    background-color: var(--header-color-light);
    color: var(--text-dark);
}

.dark{
    background-color: var(--header-color-dark);
    color:var(--text-light);
}


@keyframes rainbow {
    0% {
        color: red;
    }
    16% {
        color: orange;
    }
    32% {
        color: yellow;
    }
    48% {
        color: green;
    }
    64% {
        color: blue;
    }
    80% {
        color: indigo;
    }
    100% {
        color: violet;
    }

}

@keyframes dance {
    0% {
        color: #ff4e50;
    }
    100% {
        color: #f9d423;
    }
}

.default_page_style h1 {
    margin: 0.6rem;
    font-weight: 300;
    font-size: 2rem;

}

.default_page_style h3 {
    /*animation: rainbow 3s infinite alternate forwards;*/
    margin: 0.5rem;
    padding: 0 1.4rem;
    font-size: 2rem;
    font-weight: 400;
    /*animation: slideInFromLeft 0.5s ease-out forwards*/
}

.default_page_style p {
    flex-grow: 1;
    padding: 1.5rem 1rem;
    display: compact;
    /*color: var(--light);*/
    /*animation: slideInFromLeft 0.5s ease-out forwards;*/
}

.divider {
    border: 0;
    /*clear: both;*/
    /*margin-left: 1.5rem;*/
    /*display: block;*/
    width: 1px;
    height: 1.2rem !important;
    /*background-color: #aeaeae !important;*/
}

.default_page_style header {
    border: 0;
    clear: both;
    display: block;
    height: 1px;
    background-color: var(--light);
}

.login-container-big {
    padding-top: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 70vh;
}

.row {
    display: flex;
    flex-direction: column; /* Stack items vertically by default */
    flex-wrap: wrap;
}

.col {
    flex: 0 0 100%; /* Full width by default */
    max-width: 100%; /* Full width by default */
}

.col-40 {
    flex: 0 0 40%; /* Full width by default */
    max-width: 40%; /* Full width by default */
}

.col-60 {
    flex: 0 0 60%; /* Full width by default */
    max-width: 60%; /* Full width by default */
}

.col-30 {
    flex: 0 0 30%; /* Full width by default */
    max-width: 30%; /* Full width by default */
}

.col-70 {
    flex: 0 0 70%; /* Full width by default */
    max-width: 70%; /* Full width by default */
}

.col-20 {
    flex: 0 0 20%; /* Full width by default */
    max-width: 20%; /* Full width by default */
}

.col-80 {
    flex: 0 0 80%; /* Full width by default */
    max-width: 80%; /* Full width by default */
}

.col_full {
    flex: 0 0 100%; /* Full width by default */
    max-width: 100%; /* Full width by default */
    margin: 0 10px;
}


/* Media query for larger screens */
@media (min-width: 1300px), (pointer: none), (pointer: coarse) {
    .row {
        flex-direction: row; /* Side by side items */
        flex-wrap: wrap;
        width: 100%; /* Ensure it takes up the full width */
    }

    .col {
        flex: 1; /* Allow columns to grow and fill the available space */
        max-width: 100%; /* Override previous max-width to allow full width usage */
    }
}

.row-2 {
    display: flex;
    justify-content: space-between;
}

.row-2 > div:first-child {
    width: 90%;
    text-align: left;
}

.col_end {
    display: flex;
    justify-content: flex-end;
    width: 10%;
}


.bubble-container {
    flex-grow: 1;
    display: compact;
    border: 1px solid var(--dark-gray);
    padding: 0 0 0.5vw 0;
    margin: 2vw 0 0 0;
    border-radius: 10px;
    /*box-shadow: 9px 11px 8px rgba(0, 0, 0, 0.2);*/
}

.component-container {
    /*border: var(--dark-gray) 1px solid;*/
    margin: 1.5rem 1.5rem;
}

.settings-container {
    background-color: var(--light);
    border: var(--dark-gray) 1px solid;
    margin: 1.5rem 1.5rem;
    padding: 1.5rem 1.5rem;
}

.dropdown-menu-denda {
    /* Style for the dropdown menu */
    position: absolute;
    color: var(--text-light);
    background-color: var(--main);
    border: 1px solid var(--light);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    width: 150px; /* or max-width if you want it to be smaller */
    top: 80%; /* This positions the top of the dropdown right at the bottom of the title container */
    right: -100px; /* This positions the left of the dropdown right at the left of the title container */
    z-index: 1009; /* This ensures the dropdown is on top of other elements */
}

.dropdown-menu-denda select {
    background-color: var(--main-hover);
    color: var(--dark);
}

.dropdown-menu-denda div {
    /* Style for each dropdown item */
    padding: 5px;
    border: 0;
    border-bottom: 1px solid var(--dark-gray);
    cursor: pointer;
}

.dropdown-menu-denda div:last-child {
    border-bottom: none;
}

.dropdown-menu-denda div:hover {
    background-color: var(--main-hover);
    /*color: var(--dark);*/
    transition: color .15s ease-in-out, background-color .25s ease-in-out, border-color .15s ease-in-out;
}

.dropdown-menu-denda-general {
    /* Style for the dropdown menu general */
    display: inline-block;
    color: var(--text-light);
    background-color: var(--main);
    border: 1px solid var(--light);
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    width: 13vh; /* or max-width if you want it to be smaller */
    z-index: 100; /* This ensures the dropdown is on top of other elements */
}

.dropdown-menu-denda-general select {
    background-color: var(--main-hover);
    color: var(--dark);
}

.dropdown-menu-denda-general div {
    /* Style for each dropdown item */
    padding: 5px;
    border: 0;
    border-bottom: 1px solid var(--dark-gray);
    cursor: pointer;
}

.dropdown-menu-denda-general div:last-child {
    border-bottom: none;
}

.dropdown-menu-denda-general div:hover {
    background-color: var(--main-hover);
    /*color: var(--dark);*/
    transition: color .15s ease-in-out, background-color .25s ease-in-out, border-color .15s ease-in-out;
}

.title-with-dropdown {
    top: 0;
    margin: 0;
    position: relative;
    flex-wrap: nowrap;
    align-items: center;
    display: flex;
    justify-content: space-between;
    padding: 0;

}

.picker {
    position: absolute;
    top: 100%; /* Position it below the title */
    left: 0;
    margin-left: 2rem;
    width: 19rem;
}

.vr {
    border-left: 1px solid var(--dark-gray);
}

.date-container { /*makes content go all the way to the right*/
    display: flex;
    padding-right: 10px;
    align-items: center;
    justify-content: space-between;
}

.Button {
    background-color: var(--main);
    border: none;
    color: var(--text-light);
    padding: 1rem;
    height: 2.5rem;
    align-items: center;
    text-align: center;
    text-decoration: none;
    display: flex;
    margin: 4px 2px;
    cursor: pointer;
    box-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 6px;
    transition: background-color 0.3s ease; /* Smooth transition for hover */
    font-weight: 700;
    text-wrap: nowrap;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.25);
    text-transform: uppercase;
}

.button:hover {
    background-color: var(--main-hover);
}

.footer {
    width: 100%;
    height: 27px;
    position: fixed;
    bottom: 0; /* Ensures footer sticks to the bottom */
    background: var(--header-color-light);
    color: white;
    font-family: 'Inter', sans-serif;
    font-size: 0.8rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    justify-content: center;
    /*z-index: 2000;*/
}

.logo {
    padding: 0;
    object-fit: contain;
    /*max-width: 30vh;*/
}

@media (max-width: 768px), (pointer: none), (pointer: coarse) {
    .logo {
        max-width: 40vh;
    }

    .component-container {
        margin: 1.5rem 0.5rem;
    }
}

.header-container .logo {
    height: 100px; /* Adjust based on your preference */
    width: auto; /* Maintain aspect ratio */
    margin: 0; /* Adjust based on your preference */

}

.hide-logo {
    height: 100px; /* Adjust based on your preference */
    width: 0;
    display: none;
}

.feedback-container {
    color: var(--text-dark);
    width: 100%;
    display: inline-block;
    overflow-x: auto; /* Enable horizontal scrolling */
    gap: 20px; /* Add space between each child */
    margin: 0; /* Add space above and below the container */
    text-align: left;
    text-wrap: nowrap;
}

.fade-out {
    opacity: 0;
    pointer-events: none; /* Prevent interaction with faded out element */
}

@media (max-width: 768px), (pointer: none), (pointer: coarse) {
    .padding-on {
        margin-left: 0; /* Adjust as needed when sidebar is open */
        transition: 0.5s;
    }

    .padding-off {
        margin: 0 0 4vh 0 !important; /* Or adjust as needed when sidebar is closed */
        transition: 0.5s;
        padding: 0 !important;
    }
}